import $ from 'jquery'

export default class mobileToggle {
  constructor (element) {
    this.$element = $(element)
    this.$nav = this.$element.closest('nav')
    this.$element.on('click', this.handleButtonClick.bind(this))
  }

  handleButtonClick (event) {
    event.preventDefault()
    this.$element.toggleClass('bi-x')
    this.$element.toggleClass('bi-list')

    this.$nav.toggleClass('navbar-mobile')
  }
}
